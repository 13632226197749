// src/pages/Home.js
import React, { useState } from 'react';
import ApiKeyInput from '../components/ApiKeyInput';
import SkillCard from '../components/SkillCard';
import CareerPathway from '../components/CareerPathway';

const Home = () => {
  const [selectedPathway, setSelectedPathway] = useState(null);
  const [selectedMajor, setSelectedMajor] = useState(null);

  const careerPathways = [
    { name: 'Technology', disabled: true },
    { name: 'STEM', disabled: true },
    { name: 'Business & Law', disabled: false },
    { name: 'Arts', disabled: true },
    { name: 'Leadership & Education', disabled: true },
  ];

  const businessMajors = [
    'Business Administration',
    'Marketing & Advertising Skills',
    'Pre-Law'
  ];

  const marketingSkills = [
    { name: 'Digital Marketing', verifiable: true },
    { name: 'Content Creation', verifiable: false },
    { name: 'Market Research', verifiable: false },
    { name: 'Brand Management', verifiable: false },
    { name: 'Social Media Strategy', verifiable: false },
  ];

  return (
    <div className="home-container">
      <h1>Welcome to SkillProof</h1>
      <ApiKeyInput />
      
      <div className="career-pathways">
        <h2>Select Your Career Pathway</h2>
        <div className="pathway-buttons">
          {careerPathways.map((pathway) => (
            <CareerPathway
              key={pathway.name}
              name={pathway.name}
              disabled={pathway.disabled}
              onClick={() => {
                if (!pathway.disabled) {
                  setSelectedPathway(pathway.name);
                  setSelectedMajor(null);
                }
              }}
              selected={selectedPathway === pathway.name}
            />
          ))}
        </div>
      </div>

      {selectedPathway === 'Business & Law' && (
        <div className="selected-pathway">
          <h3>Select Your Major</h3>
          <div className="major-buttons">
            {businessMajors.map((major) => (
              <CareerPathway
                key={major}
                name={major}
                onClick={() => setSelectedMajor(major)}
                selected={selectedMajor === major}
              />
            ))}
          </div>
        </div>
      )}

      {selectedMajor === 'Marketing & Advertising Skills' && (
        <div className="selected-major">
          <h3>Marketing & Advertising Skills</h3>
          <div className="skill-list">
            {marketingSkills.map((skill) => (
              <SkillCard 
                key={skill.name} 
                skill={skill.name} 
                verifiable={skill.verifiable}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;