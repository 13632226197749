// src/services/akashAIService.js
import axios from 'axios';

const API_URL = 'https://chatapi.akash.network/api/v1/chat/completions';
const API_KEY = process.env.REACT_APP_AKASH_API_KEY;

const useAkashAIService = () => {
  const evaluateSkill = async (skillInput) => {
    if (!API_KEY) {
      console.error('API_KEY is not set');
      return 'Configuration error';
    }

    const requestBody = {
      model: 'Meta-Llama-3-1-8B-Instruct-FP8',
      messages: [
        { role: 'system', content: 'You are an AI assistant that helps people evaluate their soft skills.' },
        { role: 'user', content: skillInput },
      ],
      max_tokens: 800,
      temperature: 0.7,
      frequency_penalty: 0,
      presence_penalty: 0,
      top_p: 0.95,
    };

    try {
      const response = await axios.post(API_URL, requestBody, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${API_KEY}`,
        },
      });

      if (response.status === 200 && response.data.choices && response.data.choices.length > 0) {
        return response.data.choices[0]?.message?.content || 'No content returned';
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error fetching data from AkashAI:', error.message);
      return 'Error fetching data from AkashAI';
    }
  };

  return { evaluateSkill };
};

export default useAkashAIService;
