// src/services/openaiService.js
import axios from 'axios';
import { useContext } from 'react';
import { ApiKeyContext } from '../context/ApiKeyContext';

const API_URL = process.env.REACT_APP_OPENAI_API_URL;

const useOpenAIService = () => {
  const { apiKey } = useContext(ApiKeyContext);

  const evaluateSkill = async (skillInput) => {
    if (!apiKey) {
      console.error('API_KEY is not set');
      return 'Configuration error';
    }

    if (!API_URL) {
      console.error('API_URL is not set');
      return 'Configuration error';
    }

    const requestBody = {
      messages: [
        { role: 'system', content: 'You are an AI assistant that helps people evaluate their soft skills.' },
        { role: 'user', content: skillInput },
      ],
      max_tokens: 800,
      temperature: 0.7,
      frequency_penalty: 0,
      presence_penalty: 0,
      top_p: 0.95,
      stop: null,
    };

    try {
      const response = await axios.post(API_URL, requestBody, {
        headers: {
          'Content-Type': 'application/json',
          'api-key': apiKey,
        },
      });

      if (response.status !== 200) {
        throw new Error('Failed to fetch response from the AI assistant');
      }

      return response.data.choices[0]?.message.content;
    } catch (error) {
      console.error('Error fetching data from the API:', error.message);
      return 'Error fetching data from the API';
    }
  };

  return { evaluateSkill };
};

export default useOpenAIService;
