// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ApiKeyProvider } from './context/ApiKeyContext';
import Home from './pages/Home';
import SkillAssessment from './pages/SkillAssessment';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import './styles/components.css';

function App() {
  return (
    <ApiKeyProvider>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/skills/:skill" element={<SkillAssessment />} />
        </Routes>
        <Footer />
      </Router>
    </ApiKeyProvider>
  );
}

export default App;
