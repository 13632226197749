// src/components/CareerPathway.js
import React from 'react';

const CareerPathway = ({ name, disabled, onClick, selected }) => {
  return (
    <button
      className={`career-pathway ${disabled ? 'disabled' : ''} ${selected ? 'selected' : ''}`}
      onClick={onClick}
      disabled={disabled}
    >
      {name}
    </button>
  );
};

export default CareerPathway;