// src/pages/SkillAssessment.js
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import useOpenAIService from '../services/openaiService';
import useAkashAIService from '../services/AkashAIService';

const SkillAssessment = () => {
  const { skill } = useParams();  // Get the skill name from the URL
  const [response, setResponse] = useState(null);  // To store the response from the API
  const [inputText, setInputText] = useState('');  // To store the user input
  const [useAkash, setUseAkash] = useState(false);  // To toggle between Akash and OpenAI

  const { evaluateSkill: evaluateSkillOpenAI } = useOpenAIService();  // Hook to call the OpenAI API
  const { evaluateSkill: evaluateSkillAkash } = useAkashAIService();  // Hook to call the Akash API

  const handleEvaluateSkill = async () => {
    if (inputText.trim() === '') {
      console.log('Input is empty');
      return;
    }

    try {
      const evaluateSkill = useAkash ? evaluateSkillAkash : evaluateSkillOpenAI;
      const result = await evaluateSkill(inputText);
      setResponse(result);  // Update the response state
    } catch (error) {
      console.error('Error evaluating skill:', error);
      setResponse('An error occurred. Please try again later.');
    }
  };

  const toggleService = () => {
    setUseAkash(!useAkash);
  };

  return (
    <div>
      <h2>Assess {skill}</h2>
      <textarea
        value={inputText}
        onChange={(e) => setInputText(e.target.value)}
        placeholder={`Enter your response for ${skill}`}
      />
      <button onClick={handleEvaluateSkill}>Submit</button>
      <button onClick={toggleService}>
        {useAkash ? 'Switch to OpenAI' : 'Switch to AkashAI'}
      </button>
      {response && <p>{response}</p>}
    </div>
  );
};

export default SkillAssessment;
