// src/components/SkillCard.js
import React from 'react';
import { Link } from 'react-router-dom';

const SkillCard = ({ skill, verifiable }) => {
  return (
    <div className="skill-card">
      <h4>{skill}</h4>
      {verifiable ? (
        <Link to={`/skills/${encodeURIComponent(skill)}`}>
          <button className="verify-btn">Verify Skill</button>
        </Link>
      ) : (
        <button className="verify-btn disabled" disabled>Verify Skill</button>
      )}
    </div>
  );
};

export default SkillCard;
