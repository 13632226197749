// src/components/ApiKeyInput.js
import React, { useState, useContext } from 'react';
import { ApiKeyContext } from '../context/ApiKeyContext';

const ApiKeyInput = () => {
  const { apiKey, setApiKey } = useContext(ApiKeyContext);
  const [inputValue, setInputValue] = useState(apiKey || '');

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSaveClick = () => {
    setApiKey(inputValue);
    localStorage.setItem('apiKey', inputValue); // Optional: Save to local storage
  };

  return (
    <div>
      <h2>Enter API Key</h2>
      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        placeholder="Enter your API key here"
      />
      <button onClick={handleSaveClick}>Save</button>
    </div>
  );
};

export default ApiKeyInput;
